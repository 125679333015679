
//import { useLocalStorage } from "./../hooks/UseLocalStorage";

export default function HomePage() {

    //const [user] = useLocalStorage("user");

    return (

            <label>Ce portail vous permet de mettre à jour vos casques de réalité virtuelle.</label>

    )
}