import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./UseLocalStorage";
import API from "../FeeluApi"

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const login = async (data) => {
        setUser(null);
        API.Authentication(data.email.trim(), data.password.trim(), ((ex, resp) => {
          if (ex !== null){
            alert('Erreur de login ou mot de passe');
            return;
          }
          setUser(resp);
          navigate("/updatedevice", { replace: true });
        }));
  };

  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
