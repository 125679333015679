import Box from '@mui/material/Box';
import { Button } from '@mui/material';

export default function StepTurnOn({next}) {

    function handleNext(e) {
        e.preventDefault();
        next();
    }

    return (
        <Box sx={{ mr: 1, objectFit: 'contain' }}>
            <Box>
                <label>
                    Appuyez sur le bouton d'allumage sous les casque jusqu'à ce que la petite lumière bleue juste coté s'allume.
                </label>
            </Box>

            <Box component="img" src="img/device/pico-allumage.png" alt='allumage' loading="lazy" sx={{ width: 300, objectFit: 'contain' }} />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={(e) => { handleNext(e) }} sx={{ mr: 1 }}>
                    Suivant
                </Button>

            </Box>
        </Box>
    );
}