import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { usePicoDevice } from '../../hooks/UsePicoDeviceManager.js';

export default function StepConnect({ back }) {

    const picoDevice = usePicoDevice();

    function handleBack(e){
        e.preventDefault();
        back();
    }
    function handleNext(e) {
        e.preventDefault();
       picoDevice.connect_usb();
    }

    return (
        <Box sx={{ mb: 2 }}>
            <Box>
                <Typography component="label">
                    Branchez le cable USB sur le casque puis sur votre ordinateur. <br/>
                    Cliquez sur Connecter et sélectionnez la première ligne, puis Connexion.
                </Typography >
            </Box>
            <Box>
                <Box component="img" src="img/device/pico-usb-c.png" alt='allumage' loading="lazy" sx={{ width: 300, objectFit: "contain" }}></Box>
                <Box component="img" src="img/device/device-window.png" alt='allumage' loading="lazy" sx={{ width: 300, objectFit: "contain" }}></Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    variant="contained"
                    onClick={(e) => { handleBack(e) }}
                    sx={{ mr: 1 }}
                >
                    Retour
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={(e) => { handleNext(e) }} sx={{ mr: 1 }}>
                    Connecter
                </Button>

            </Box>

        </Box>
    );
}