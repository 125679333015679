import axios from 'axios';
import moment from 'moment';

const Api =   {
    httpClient: axios.create({
        // baseURL: `http://localhost:52635/api/`,
        // baseURL: `https://feelu-dev-appservice-api.azurewebsites.net/api/`,
        baseURL: `https://api.feelu.fr/api/`,
        headers: {
            FeeluAppKey: '277C9985-25A7-4921-94CD-0FAEA28EAF4B' // ,
            // Authorization: localStorage.token ? `Bearer ${localStorage.token}` : ''
        }
    }),
    configureAxios() {
        if (localStorage.token && this.tokenIsValid()) {
            this.httpClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
        }
        this.httpClient.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            let originalRequest = error.config
            if (error.response.status === 401) {
                delete localStorage.token;
                this.$router.push({ name: 'login' });
            }
            if (error.response.status === 404 && !originalRequest._retry) {
                originalRequest._retry = true
                window.location.href = '/'
                return;
            }
            // Do something with response error
            return Promise.reject(error);
        });
    },

    tokenIsValid() {
        if (!localStorage.token || !localStorage.tokenExpiration) {
            return false;
        }
        let expirationDate = moment.unix(localStorage.tokenExpiration);
        return moment().isBefore(expirationDate);
    },

    Authentication(email, password, callback) {
        var _httpClient = this.httpClient;
        this.httpClient
            .post('Authentication', {
                email: email,
                password: password
            })
            .then(response => {
                _httpClient.defaults.headers.common['Authorization'] = `Bearer ${response.data.content.feeluToken}`
                callback(null, response.data.content);
            })
            .catch(ex => {
                callback(ex);
            });
    },
    async RefreshToken(email, token) {
        var _httpClient = this.httpClient;
        const refreshTokenResponse = await this.httpClient
            .post('Authentication/RefreshToken', {
                email: email,
                token: token
            });
        if (refreshTokenResponse.data) {
            _httpClient.defaults.headers.common['Authorization'] = `Bearer ${refreshTokenResponse.data}`;
            return refreshTokenResponse.data;
        }
    },
    ResetPassword(email, callback) {
        this.httpClient.post('Registration/ForgotPassword', JSON.stringify(email),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                callback(null, response.data);
            })
            .catch(ex => {
                callback(ex);
            });
    }
}

export default Api;
