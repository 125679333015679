import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: '#f5ab17',
    },
    secondary: {
      main: '#a9337f',
    },
  },
});

export default theme;