import { Box, Container } from "@mui/system"
import SignIn from "../components/SignIn"

export default function LoginPage() {
    return (    
        
        <Box>
            <Container component="main" maxWidth="xs">
            <img 
                    alt=""
                    className=""
                    src="img/logo-horizon.png"/>
            </Container>
            <SignIn />        
        </Box>
        
    )
}