import React from "react";
import { Box } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';

export default function Layout({
    Header,
    Footer,
    children,
}) {
    return (
        <Box display="flex" flexDirection="row">

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    minHeight: "100vh",
                }}
            >
                                         {Header && <Header   />}
                       <Toolbar>       
                <Box
                    component="main"
                    sx={{
                        p: { xs: 1, md: 2, lg: 3 },
                        flexGrow: 1,
                        bgcolor: (theme) => theme.palette.background.default,
                    }}
                >

                    {children}
                </Box>
                </Toolbar>
            </Box>
           
            {Footer && <Footer />}
        </Box>
    );
};