import { useState } from 'react';
import { Button, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { usePicoDevice } from '../../hooks/UsePicoDeviceManager.js';
import TransfertProgress from './TransfertProgress.js';

export default function StepUpdateDevice({ back, reset }) {

    const { updateProgress, setUpdateProgress } = useState(0);

    const picoDevice = usePicoDevice();

    function handleBack(e) {
        e.preventDefault();
        back();
    }
    function handleInstallApk(e) {
        e.preventDefault();
        picoDevice.install();
    }

    function handleReset(e) {
        e.preventDefault();
        reset();
    }

    const changeKiosMode = () => {
        picoDevice.setKioskModeEnabled();
      };

    return (
        <Box sx={{ mb: 2 }}>
            <Box>
                <Typography>{picoDevice.deviceState}</Typography>
                <Typography>Vous êtes connecté au casque Pico avec le numéro de série :{picoDevice.deviceInfo.serialNumber}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <label>
                    <input
                        type="checkbox"
                        checked={picoDevice.kioskModeEnabled}
                        onChange={e => picoDevice.setKioskModeEnabled(!picoDevice.kioskModeEnabled)}
                    />
                    Mode Kiosk
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={picoDevice.controllerAtStart}
                        onChange={e => picoDevice.setControllerAtStart(!picoDevice.controllerAtStart)}
                    />
                    Manette au démarrage
                </label>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
                <Button variant="contained" onClick={(e) => { handleInstallApk(e) }} sx={{ mr: 2 }}>
                    Mettre à jour l'application FeelU
                </Button>
                </Box>
                {picoDevice.downloadProgress > 0 && <TransfertProgress progress={picoDevice.downloadProgress} title="01 Téléchargement de la mise à jour" />}
                {picoDevice.uploadProgress > 0 && <TransfertProgress progress={picoDevice.uploadProgress} title="02 Installation sur le casque" />}
                <Typography>{picoDevice.executeOutput}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    variant="contained"
                    onClick={(e) => { handleBack(e) }}
                    sx={{ mr: 1 }}
                >
                    Retour
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={(e) => { handleReset(e) }} sx={{ mr: 1 }}>
                    Configurer à un nouveau casque
                </Button>

            </Box>

        </Box>
    );
}