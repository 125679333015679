
import React, { useEffect, useState, useContext } from "react";
import { WebAdb } from "../tools/webadb";
import axios from "axios";
import { setupPicoDeviceData } from "../constants/setupPicoDevice";


const spdd = setupPicoDeviceData;

const PicoDeviceContext = React.createContext();

export const PicoDeviceProvider = ({ children }) => {

    const [deviceState, setDeviceState] = useState("ready");
    const [webUsb, setWebUsb] = useState(null);
    const [adb, setAdb] = useState(null);
    const [connectMessage, setConnectMessage] = useState('');
    const [fastboot, setFastboot] = useState(null);
    const [pushFiles, setPushFiles] = useState(null);
    const [executeOutput, setExecuteOutput] = useState('');
    const [xfer_stats_done, setXfer_stats_done] = useState(0);
    const [xfer_stats_time, setXfer_stats_time] = useState(0);
    const [isConnected, setIsConnected] = useState(false);
    const [deviceInfo, setDeviceInfo] = useState({});
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [downloadEnded, setDownloadEnded] = useState(false);
    const [kioskModeEnabled, setKioskModeEnabled] = useState(true);
    const [controllerAtStart, setControllerAtStart] = useState(true);
    const Adb = WebAdb();



    const connect_usb = async () => {
        try {
            if (webUsb != null) {
                disconnect_usb();
                setDeviceState("ready");
                return;
            } else {
                let newWebUsb = await Adb.open("WebUSB");
                if (!newWebUsb || !(newWebUsb.isAdb() || newWebUsb.isFastboot()))
                    throw new Error("Could not open either ADB or Fastboot");
                setWebUsb(newWebUsb);                
            }
        }
        catch (error) {
            console.log(error);
            setWebUsb(null);
            setConnectMessage(error.message);
            return false;
        }
        return true;
    }

    const execute_usb = async (cmd) => {
        console.log(cmd);
        let decoder = new TextDecoder();

        try {
            if (adb != null) {
                setDeviceState("running");

                let shell = await adb.open(cmd);
                let r = await shell.receive();
                while (r.cmd === "WRTE") {
                    if (r.data != null) {
                        setExecuteOutput(decoder.decode(r.data));
                    }

                    shell.send("OKAY");
                    r = await shell.receive();
                }

                shell.close();
                shell = null;
                setDeviceState("connected");
            }

            if (fastboot != null) {
                setDeviceState("running");

                await fastboot.send(cmd);
                let r = await fastboot.receive();
                while (fastboot.get_cmd(r) === "INFO") {
                    setExecuteOutput(`${decoder.decode(fastboot.get_payload(r))} \n`);
                    r = await fastboot.receive();
                }

                let payload = fastboot.get_payload(r);
                if (payload.length > 0)
                    payload += "\n";
                setDeviceState("connected");
                setExecuteOutput(`${decoder.decode(payload)}`);
            }
        }
        catch (error) {
            console.log(error);
            setConnectMessage(error.message);
            setWebUsb(null);
        }
    }

    const disconnect_usb = async () => {
        /*TODO  */
        /* if (this.state.shell != null)
             this.state.shell.close();
         if (this.state.sync != null)
             await this.state.sync.abort();*/
        if (webUsb)
            await webUsb.close();
        setWebUsb(null);
    }


    const xfer_stats = (start_time, done, total) => {
        let now = Date.now();
        let oldXfer_stats_time = xfer_stats_time;
        let oldXfer_stats_done = xfer_stats_done;

        if (now - oldXfer_stats_time < 500)
            return;

        if (oldXfer_stats_done > done)
            oldXfer_stats_done = 0;
        if (oldXfer_stats_time < start_time)
            oldXfer_stats_time = start_time;

        let delta = Math.round((now - start_time) / 1000);
        let instant = Math.round(((done - oldXfer_stats_done) * 1000) / ((now - oldXfer_stats_time) * 1024));
        let average = Math.round(done * 1000 / ((now - start_time) * 1024));

        let out = "";
        out += Math.round(100 * done / total) + "% (";
        setUploadProgress(Math.round(100 * done / total));
        out += Math.round(done / 1024) + " KiB in ~" + delta + " secs at avg " + average + " KiB/s, cur " + instant + " KiB/s)";
        setExecuteOutput(out)
        setXfer_stats_done(done);
        setXfer_stats_time(now);
    }


    const push_usb = async () => {

        try {
            if (adb != null && pushFiles) {
                setDeviceState('running');
                setExecuteOutput(`Loading ${pushFiles[0].name}...`);
                setTimeout(function() {        
                //Crappy patch because webadb takes some time init connection
                 }, 2000);
                let sync = await adb.sync();

                let start_time = Date.now();
                await sync.push(pushFiles[0], spdd.tmpApkDest, spdd.pushMode,
                    (done, total) => xfer_stats(start_time, done, total));


                await sync.quit();
                sync = null;
                setDeviceState('connected');
            }
        }
        catch (error) {
            console.log(error);
            setExecuteOutput(error.message);
            setDeviceState('connected');
        }
    }


    const download_apk = async () => {

        var response = await axios({
            url: 'fr.feelu.experience.apk',
            method: 'GET',
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setDownloadProgress(progress);
            }

        });

        await setPushFiles([new Blob([response.data])]);
    }

    const kioskModeChanged = () => {
        setKioskModeEnabled(!kioskModeEnabled);
    }

    const install = async () => {
        setDeviceState("downloading");
        await download_apk();
        setDownloadEnded(true);

        setDeviceState("connected")
    }



    const deviceIsConnected = (deviceState !== 'ready');
    useEffect(() => {

        setIsConnected(deviceIsConnected);

    }, [deviceState, deviceIsConnected]);

    useEffect(() => {
        if (!webUsb) {
            setKioskModeEnabled(true);
            setControllerAtStart(false);
            setAdb(null);
            setDeviceState("ready");
            setFastboot(null);
            setConnectMessage('');
            setExecuteOutput('');
            setDownloadProgress(0);
            setUploadProgress(0);
            setPushFiles(null);
            setDownloadEnded(false);
            return;
        }

        const connectDevice = async () => {

            if (deviceIsConnected){
                return;
            }
            setDeviceState("connecting");
            if (webUsb.isFastboot()) {
                try {
                    let fastBoot = webUsb.connectFastboot();
                    if (fastBoot != null) {
                        console.log("FASTBOOT mode");
                        this.setFastboot(fastBoot);
                        //  this.execute_cmd("getvar:all");
                    }
                }
                catch (error) {
                    console.log(error);
                    let msg = error.message + " Ensure that the USB port is not in use (i.e. adb server is running).";
                    setConnectMessage(msg)
                    return;
                }
            }

            if (webUsb.isAdb()) {
                try {

                    let adbConnect = await webUsb.connectAdb("host::", () =>
                        null);
                    //setConnectMessage("Please check the screen of your " + webUsb.device.productName + ".")
                    if (adbConnect != null) {
                        console.log("ADB mode");
                        setAdb(adbConnect);
                    }
                }
                catch (error) {
                    console.log(error);
                    setConnectMessage(error.message + " Ensure that the USB port is not in use (i.e. adb server is running).");
                    return;
                }
            }

            setDeviceState("connected");

            let message = "";
            if (webUsb.isAdb()) {
                message = "ADB: ";
                setDeviceInfo(webUsb.device);

            }
            if (webUsb.isFastboot())
                message = "FASTBOOT: ";

            message += `${webUsb.device.productName} ( ${webUsb.device.manufacturerName} )`;
            setConnectMessage(message);
        };

        connectDevice().catch(console.error);
        return;

    }, [webUsb, deviceIsConnected]);

    useEffect(() => {
        const installApkOnDevice = async () => {
            await push_usb();
            await execute_usb('shell:pm install -r -t ' + spdd.tmpApkDest);
            if (kioskModeEnabled)
                await execute_usb('shell:setprop persist.pxr.force.home fr.feelu.experience,com.unity3d.player.UnityPlayerActivity');
            else
                await execute_usb('shell:setprop persist.pxr.force.home \'\'');

            if (controllerAtStart)
                await execute_usb('shell:setprop persist.pvr.openrecenter 0');
            else
                await execute_usb('shell:setprop persist.pvr.openrecenter 1');

            await execute_usb('reboot');
        };
        if (downloadEnded)
            installApkOnDevice();
    }, [downloadEnded]); // eslint-disable-line react-hooks/exhaustive-deps

    return <PicoDeviceContext.Provider
        value={{
            connect_usb, install, isConnected, deviceState, disconnect_usb,
            connectMessage, executeOutput, deviceInfo, downloadProgress, uploadProgress,
            setKioskModeEnabled, kioskModeEnabled,
            setControllerAtStart, controllerAtStart
        }}
    >{children}</PicoDeviceContext.Provider>;
};

export const usePicoDevice = () => {
    return useContext(PicoDeviceContext);
}