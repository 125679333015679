import {  useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Container, StepContent } from '@mui/material';

import StepTurnOn from '../components/UpdateDevice/StepTurnOn';
import StepConnect from '../components/UpdateDevice/StepConnect';
import StepUpdateDevice from '../components/UpdateDevice/StepUpdateDevice.js';
import { usePicoDevice } from '../hooks/UsePicoDeviceManager.js';


export default function UpdateDevicePage() {

    const [activeStep, setActiveStep] = useState(0);
    const picoDevice = usePicoDevice();


    useEffect(() => {
        if (picoDevice.deviceState === 'ready') {
            if (activeStep > 0)
                setActiveStep(1);
            return;
        }
        if (activeStep !== 2)
            setActiveStep(2);


    }, [picoDevice.deviceState, activeStep])


    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleReset = () => {
        picoDevice.disconnect_usb();
        setActiveStep(0);
    };

    return (
        <Container maxWidth="md">
            <Stepper activeStep={activeStep} orientation="vertical">
                <Step key="1" >
                    <StepLabel>Allumer </StepLabel>
                    <StepContent>
                        <StepTurnOn next={handleNext}></StepTurnOn>
                    </StepContent>
                </Step>
                <Step key="2">
                    <StepLabel>Connecter </StepLabel>
                    <StepContent>
                        <StepConnect back={handleBack} ></StepConnect>
                    </StepContent>

                </Step>

                <Step key="3">
                    <StepLabel>Mettre à jour</StepLabel>
                    <StepContent>
                        <StepUpdateDevice back={handleBack} reset={handleReset}

                        ></StepUpdateDevice>
                    </StepContent>
                </Step>
            </Stepper>
        </Container >
    );
}