import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

export default function TransfertProgress({ progress, title, state }) {

    return (<Box>
        <Typography>{title}</Typography>
        <LinearProgress variant="determinate" value={progress} />
    </Box>);
}