import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import LoginPage from './pages/Login';
import UpdateDevicePage from './pages/UpdateDevicePage';
import HomePage from './pages/Home';
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthProvider } from "./hooks/UseAuth";
import MainMenu from './components/MainMenu';
import FeelUTheme from './feeluTheme'
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';

import Footer from './components/Footer';
import Layout from './components/layout/Layout';
import { PicoDeviceProvider } from './hooks/UsePicoDeviceManager';

function App() {

  return (
    <ThemeProvider theme={FeelUTheme}>
      <CssBaseline />
      <BrowserRouter>

        <AuthProvider>
          <Routes>
            <Route path="/" element={<Layout Footer={Footer}><LoginPage /></Layout>} />
            <Route path='/home' element={<ProtectedRoute><Layout Header={MainMenu} Footer={Footer}><HomePage /></Layout></ProtectedRoute>} />
            <Route path='/updatedevice' element={<ProtectedRoute><Layout Header={MainMenu} Footer={Footer}><PicoDeviceProvider> <UpdateDevicePage /></PicoDeviceProvider></Layout></ProtectedRoute>} />
          </Routes>
        </AuthProvider>


      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
